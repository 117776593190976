import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Button } from "@ahazou/react-components";
import MainBanner from "../components/MainBanner";
import DiagonalSection from "../components/DiagonalSection";
import Benefits from "../components/Benefits";
import HowToList from "../components/HowToList";
import DarkList from "../components/DarkList";
import Plans from "../components/Plans";
import Reviews from "../components/Reviews";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
// import text2_photos from "../images/webp/text2_photos.webp";
// import text2_photos_mid from "../images/webp/text2_photos-mid.webp";
// import text2_photos_lg from "../images/webp/text2_photos-lg.webp";
import bg_benefits_pink from "../images/webp/benefits_pink.webp";
import bg_benefits_blue from "../images/webp/benefits_blue.webp";
import bg_darklist_pink from "../images/webp/darklist_pink.webp";
import bg_darklist_blue from "../images/webp/darklist_blue.webp";
import bg_plans_pink from "../images/webp/plans_pink.webp";
import logoBanner from "../images/logo-banner.svg";
import starBanner from "../images/star-banner.svg";
import CookieConsent from "react-cookie-consent";
import "./style.css";

const Home = () => {
  const [downloadModalOpened, setDownloadModalOpened] = useState(false);
  const [goToAPP, setGoToAPP] = useState(false);

  const openDownloadModal = (e) => {
    if (!isMobile) {
      e.preventDefault();
      setDownloadModalOpened(true);
    }
  };

  console.log("goToAPP",isMobile)
  return (
    <>
      <SEO
        title="Ahazou! Seu parceiro digital para redes sociais"
        description="Conheça o aplicativo que vai profissionalizar a maneira que você se comunica nas redes sociais. Venha ver!"
      />

      <Layout
        downloadOpen={openDownloadModal}
        downloadClose={() => {
          setDownloadModalOpened(false);
        }}
        downloadModalOpened={downloadModalOpened}
      >
        <MainBanner
          onDownloadClick={openDownloadModal}
          title={`conquiste seu sucesso nas redes sociais!`}
          subtitle={`profissionalize suas redes sociais com conteúdos prontos para mais de 100 áreas de atuação`}
        />
        <div className="box-video">
          <iframe
            className="video-box"
            // width="350"
            // height="190"
            src="https://www.youtube.com/embed/nyFcSTfuRVc?controls=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>

        {/* <figure className="main-page__decoration">
          <img
            src={text2_photos}
            alt="Main Page Adorns"
            className="main-page__text2-photos"
          />
        </figure>

        <figure className="main-page__decoration">
          <img
            src={text2_photos_mid}
            alt="Main Page Adorns"
            className="main-page__text2-photos-mid"
          />
        </figure>

        <figure className="main-page__decoration">
          <img
            src={text2_photos_lg}
            alt="Main Page Adorns"
            className="main-page__text2-photos-lg"
          />
        </figure> */}

        <div className="main-page__text2">
          <DiagonalSection />
        </div>

        <figure className="main-page__decoration">
          <img
            src={bg_benefits_pink}
            alt="Background Pink"
            className="main-page__bg-benefits-pink"
          />
        </figure>
        <figure className="main-page__decoration">
          <img
            src={bg_benefits_blue}
            alt="Background Blue"
            className="main-page__bg-benefits-blue"
          />
        </figure>

        <div className="main-page__benefits">
          <Benefits />
        </div>

        <div className="main-page__test-button">
          <Button
            className="main-page__button"
            label="teste agora"
            element="a"
            href="https://ahazou.app.goo.gl/site-ahz"
            onClick={openDownloadModal}
          />
        </div>

        <div className="main-page__how-to">
          <HowToList />
        </div>

        <figure className="main-page__decoration">
          <img
            src={bg_darklist_pink}
            alt="Background Pink"
            className="main-page__bg-darklist-pink"
          />
        </figure>
        <figure className="main-page__decoration">
          <img
            src={bg_darklist_blue}
            alt="Background Blue"
            className="main-page__bg-darklist-blue"
          />
        </figure>

        <div className="main-page__darklist">
          <DarkList className="main_page__darklist-component" />
        </div>

        <div className="main-page__reviews">
          <Reviews />
        </div>

        <figure className="main-page__decoration">
          <img
            src={bg_plans_pink}
            alt="Background Pink"
            className="main-page__bg-plans-pink"
          />
        </figure>

        <div className="main-page__plans" id="plans">
          <Plans onDownloadClick={openDownloadModal} />
        </div>
        <CookieConsent buttonText="concordo" location="bottom">
          Este site usa cookies para melhorar sua experiência. Ao continuar
          navegando, você concorda com o uso de cookies.
        </CookieConsent>
      </Layout>

      {/* <a
        href="https://contate.me/ahazou-app"
        className="btn-whats"
        target="_blank"
      >
        <i style={{ marginTop: "16px" }} class="fa fa-whatsapp"></i>
      </a> */}

      {!goToAPP & isMobile && (
        <div className={`lg-hide box-banner`} id="goToAPP">
          <div className="app-pop-up-content">

            <a className="close-banner" onClick={() => setGoToAPP(true)}>
              <p className="text-white text-close">x</p>
            </a>
            <img
            width="47"
            height="47"
            src={logoBanner}
            alt="logo ahazou"
            className="logo-banner"
          />
            <div className="box-text-banner text-white">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ahazou.app.goo.gl/baixar-ahazou-agora"
                className="text-ancora"
              >
                <p>Baixe nosso App!</p>
                <img
                  width="86"
                  height="14"
                  src={starBanner}
                  className="star-img"
                  alt="estrelas"
                />
              </a>
            </div>
            <a
            target="_blank"
            className="btn-install text-ancora"
            rel="noopener noreferrer"
            href="https://ahazou.app.goo.gl/cta2-descubra"
          >
            <p className="text-install">instalar</p>
          </a>
      
          </div>
        </div>
      )}

      <a
        className="robbu-whatsapp-button"
        target="_blank"
        href="https://contate.me/ahazou-app"
      >
        <img
          width="40"
          height="50"
          src="https://cdn.positus.global/production/resources/robbu/whatsapp-button/whatsapp-icon.svg"
        />
      </a>
    </>
  );
};

export default Home;
