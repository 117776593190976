import React, { Component } from 'react';
import { Typography } from '@ahazou/react-components'
import { isMobile, isTablet } from 'react-device-detect'

class GetScreen extends Component {
  render() {
    if (isMobile && (this.props.name ==="title"))
    {
        return <Typography variant="displayL" className={this.props.className}>{this.props.children} </Typography>;
    }
    else if (isMobile && (this.props.name ==="bodySM"))
    {
        return <Typography variant="bodyS" className={this.props.className}>{this.props.children} </Typography>;
    }
    else if (isMobile && (this.props.name ==="bodyML"))
    {
        return <Typography variant="bodyM" className={this.props.className}>{this.props.children} </Typography>;
    }
    else if (isTablet && (this.props.name ==="title")) {
        return <Typography variant="displayXL" className={this.props.className}>{this.props.children} </Typography>;
    }
    else if (isTablet && (this.props.name ==="bodySM"))
    {
        return <Typography variant="bodyM" className={this.props.className}>{this.props.children} </Typography>;
    }
    else if (isTablet && (this.props.name ==="bodyML"))
    {
        return <Typography variant="bodyM" className={this.props.className}>{this.props.children} </Typography>;
    }
    else if (this.props.name ==="title")
    {
        return <Typography variant="displayXL" className={this.props.className}>{this.props.children} </Typography>;
    }
    else if (this.props.name ==="bodySM")
    {
        return <Typography variant="bodyM" className={this.props.className}>{this.props.children} </Typography>;
    }
    else
    {
        return <Typography variant="bodyM" className={this.props.className}>{this.props.children} </Typography>;
    }
  }
}

export default GetScreen;