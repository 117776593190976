import React from "react";
import "./style.css";
import GetScreen from "../GetScreen";
import { Button } from "@ahazou/react-components";
const DiagonalSection = () => {
  return (
    <div className="diagonal-section">
      <div className="diagonal-section__diagonal--first"></div>
      <div className="diagonal-section__content">
        <GetScreen name="title" className="diagonal-section__first">
          Somos a opção número 1 dos empreendedores, pela praticidade, qualidade
          e economia.
        </GetScreen>
        <GetScreen name="bodyML" className="diagonal-section__second">
          Junte-se a esses empreendedores
        </GetScreen>

        <Button
          variant="tertiary"
          bordered
          className="main-page__button btn-cta"
          label="baixe agora o ahazou"
          element="a"
          href="https://ahazou.app.goo.gl/site-ahz"
        />
      </div>
      <div className="diagonal-section__diagonal--second"></div>
    </div>
  );
};

export default DiagonalSection;
