import React, { useState } from "react";
import { Icon, Button } from "@ahazou/react-components";
import HowTo from "../HowTo";
// import SocialIcons from "../SocialIcons";
// import StoreBadge from "../StoreBadge";
import bg_pink from "../../images/webp/how-to_pink_65_11zon.webp";
import bg_green from "../../images/webp/how-to_green_68_11zon.webp";
import bg_yellow from "../../images/webp/how-to_yellow_62_11zon.webp";
import bg_blue_dots from "../../images/webp/how-to_blue-dots_71_11zon.webp";
import bg_hearts from "../../images/webp/main-banner_hearts_39_11zon.webp";
import bg_likes from "../../images/webp/main-banner_likes_36_11zon.webp";
import bg_avatar from "../../images/webp/main-banner_avatar_42_11zon.webp";
// import illus1 from "../../images/webp/illus_funciona_1@3x_57_11zon.webp";
import illus2 from "../../images/webp/illus_funciona_2_3x_resized.webp";
import illus3 from "../../images/webp/illus_funciona_3_3x_resized.webp";
import "./style.css";
import GetScreen from "../GetScreen";

const titleList = [
  {
    name: "beleza & estética",
    list: [
      "barbearia",
      "cabelo",
      "estética corporal",
      "estética facial",
      "cílios & sobrancelhas",
      "manicure & pedicure",
      "maquiagem",
      "depilação"
    ]
  },
  {
    name: "gastronomia",
    list: [
      "açougue & churrasco",
      "á la carte & self service",
      "assuntos variados de gastronomia",
      "bares",
      "buffet & eventos",
      "cafés",
      "comidas variadas",
      "confeitaria",
      "cozinha italiana",
      "cozinha japonesa",
      "cozinha mexicana",
      "doces, salgados & festas",
      "gelados & açaiteria",
      "hamburguer",
      "hortifruti",
      "hot dog",
      "marmitas",
      "padaria",
      "pastelaria",
      "peixes & frutos do mar",
      "pizzaria",
      "saudável & vegetariano"
    ]
  },
  {
    name: "saúde & bem-estar",
    list: [
      "fisioterapia",
      "fonoaudiologia",
      "massoterapia",
      "nutrição",
      "odontologia",
      "personal trainer",
      "pilates",
      "podologia",
      "saúde mental",
      "terapias complementares",
      "yoga"
    ]
  },
  {
    name: "pets",
    list: ["dog walker & petsitter", "petshop", "veterinário"]
  },
  {
    name: "marketing digital",
    list: [
      "afiliados & infoproduto",
      "coach & mentoria",
      "marketing digital",
      "vendas online"
    ]
  },
  {
    name: "moda",
    list: [
      "acessórios",
      "costura & reparos",
      "moda feminina",
      "moda infantil",
      "moda masculina",
      "moda praia"
    ]
  },
  {
    name: "influenciadores digitais",
    list: [
      "influenciador de saúde & bem-estar",
      "influenciador de moda & beleza",
      "influenciador de estilo de vida",
      "influenciador de marketing digital"
    ]
  },
  {
    name: "arquitetura & decoração",
    list: ["arquitetura, design & decoração", "móveis planejados"]
  },
  {
    name: "serviços para casa",
    list: [
      "chaveiro",
      "dedetizador",
      "faxina",
      "lavanderia",
      "limpeza de sofás & tapetes",
      "marido de aluguel"
    ]
  },
  {
    name: "reforma & reparos",
    list: [
      "eletricista",
      "pintor",
      "encanador",
      "pedreiro",
      "carreto & mudança",
      "vidraçaria"
    ]
  },
  {
    name: "revenda de marcas",
    list: [
      "maravilhas da terra",
      "avon",
      "amakha",
      "abelha rainha",
      "o boticário",
      "demillus",
      "eudora",
      "hinode",
      "jafra",
      "jequiti",
      "mahogany",
      "mary kay",
      "natura",
      "racco",
      "hope",
      "liebe lingerie",
      "valisere",
      "romance",
      "jogê",
      "recco",
      "rommanel",
      "herbalife",
      "tupperware"
    ]
  },
  {
    name: "religião & espiritualidade",
    list: ["igrejas & espiritualidade cristã", "outra fés & religiões"]
  },
  {
    name: "óticas",
    list: ["óticas"]
  },
  {
    name: "ensino",
    list: [
      "ensino particular & preparatório",
      "línguas estrangeiras",
      "música & instrumentos"
    ]
  },
  {
    name: "tatuagem & piercing",
    list: ["estúdios, tatuadores & body piercer"]
  },
  {
    name: "serviços automotivos",
    list: [
      "mecânica automotiva",
      "elétrica automotiva",
      "estética automotiva & lavajato"
    ]
  },
  {
    name: "turismo",
    list: ["agências & agentes de viagem"]
  },
  {
    name: "assistência técnica",
    list: [
      "computadores, celulares & tablets",
      "eletrônicos & eletrodomésticos"
    ]
  },
  {
    name: "fotografia",
    list: ["fotógrafos & estúdios de fotografia"]
  },
  {
    name: "imóveis",
    list: ["imobiliárias, corretores & construtoras"]
  }
];
const HowToList = () => {
  const [showList, setShowList] = useState("");

  const handleShow = (item) => {
    if (item !== showList) {
      setShowList(item);
    } else {
      setShowList("");
    }
  };

  return (
    <div className="how-to-list">
      <GetScreen name="title" className="how-to-list__title">
        como funciona?
      </GetScreen>

      <figure className="how-to-list__decoration">
        <img
          src={bg_blue_dots}
          alt="Blue dots"
          className="how-to__bg-blue-dots"
        />
      </figure>

      <figure className="how-to-list__decoration">
        <img src={bg_pink} alt="Background Pink" className="how-to__bg-pink" />
      </figure>

      <figure className="how-to-list__decoration">
        <img
          src={bg_green}
          alt="Background Green"
          className="how-to__bg-green"
        />
      </figure>

      <div className="how-to-list__list">
        <div className="how-to-list__item">
          <HowTo
            number={"1"}
            title={"selecione sua área de atuação"}
            subtitle={
              "atendemos mais de 100 áreas de atuação, baixe o app e selecione a sua"
            }
          />
          <div className="list-segment">
            <ul>
              {titleList.map((item, idx) => (
                <li key={idx}>
                  <b onClick={() => handleShow(item.name)}> {item.name}</b>
                  {showList === item.name && (
                    <ul key={item} className="sub-list-segment">
                      {item.list.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="how-to-list__item">
          <HowTo
            number={"2"}
            title={"escolha o conteúdo ideal para você"}
            subtitle={
              "entre as mais de 350 mil opções para publicar diariamente"
            }
          />
          <figure className="how-to-list__decoration--second">
            <img
              src={illus2}
              alt="Decoration"
              className="how-to-list__decoration__image how-to-list__decoration__image--second"
            />
          </figure>
        </div>

        <div className="how-to-list__item">
          <HowTo
            number={"3"}
            title={"personalize o post com sua logo"}
            subtitle={
              "use o editor para adicionar logo, contato e outras informações"
            }
          />

          <figure className="how-to-list__decoration--third">
            <img
              src={illus3}
              alt="Decoration"
              className="how-to-list__decoration__image how-to-list__decoration__image--third"
            />
          </figure>
        </div>
      </div>

      <figure className="how-to-list__decoration">
        <img
          src={bg_yellow}
          alt="Yellow Background"
          className="how-to__bg-yellow"
        />
      </figure>

      <figure className="how-to-list__decoration">
        <img
          src={bg_avatar}
          alt="Background Avatar"
          className="how-to__bg-avatar how-to-list__bg-optional"
        />
      </figure>
      <figure className="how-to-list__decoration">
        <img
          src={bg_likes}
          alt="Background Likes"
          className="how-to__bg-likes how-to-list__bg-optional"
        />
      </figure>
      <figure className="how-to-list__decoration">
        <img
          src={bg_hearts}
          alt="Background Hearts"
          className="how-to__bg-hearts how-to-list__bg-optional"
        />
      </figure>

      <div className="how-to-list__footer">
        <div className="how-to-list__footer--first">
          <div className="first-line">
            <GetScreen name="title">pronto!</GetScreen>
            <Icon
              size="l"
              icon="mono_like"
              className="how-to-list__icon-like"
            />
          </div>
          <p className="how-to-list__footer--subtitle">
            tudo isso em 3 minutos
          </p>
        </div>

        <div className="how-to-list__footer--third">
          <GetScreen name="bodySM">
            compartilhe diretamente no
            <strong>WhatsApp, Instagram, Facebook</strong> ou outra rede de sua
            preferência
          </GetScreen>
          <Button
            className="main-page__button btn-cta"
            label="experimente agora mesmo"
            element="a"
            href="https://ahazou.app.goo.gl/site-ahz"
          />
        </div>
      </div>
    </div>
  );
};

export default HowToList;
