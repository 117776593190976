import React from "react";
// import StoreBadge from "../StoreBadge";
// import depoimentos from "../../images/depoimentos.svg";
import app_e_nota from "../../images/nota-ahazou.svg";
import ImageGallery from "react-image-gallery";
import depoimento1 from "../../images/preview/depoimento 1.svg";
import depoimento2 from "../../images/preview/depoimento 2.svg";
import depoimento3 from "../../images/preview/depoimento 3.svg";
import depoimento4 from "../../images/preview/depoimento 4.svg";
import depoimento5 from "../../images/preview/depoimento 5.svg";
import depoimento6 from "../../images/preview/depoimento 6.svg";
import { Button } from "@ahazou/react-components";
import "./style.css";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: depoimento1,
    thumbnail: depoimento1
  },
  {
    original: depoimento2,
    thumbnail: depoimento2
  },
  {
    original: depoimento3,
    thumbnail: depoimento3
  },
  {
    original: depoimento4,
    thumbnail: depoimento4
  },
  {
    original: depoimento5,
    thumbnail: depoimento5
  },
  {
    original: depoimento6,
    thumbnail: depoimento6
  }
];
const Reviews = () => {
  return (
    <div className="reviews">
      <figure className="reviews__icon">
        <img
          src={app_e_nota}
          alt="app e nota"
          className="reviews__icon__image"
        />
      </figure>
      <div className="reviews__title">
        <p className="reviews__title-text">nossos clientes amam nosso app</p>
      </div>
      <div className="reviews__carroussel">
        <ImageGallery showThumbnails={true} showFullscreenButton={false} showPlayButton={false} items={images} />
      </div>
      <div className="reviews__badge">
        <Button
          bordered
          className="main-page__button btn-cta"
          label="baixe app ahazou"
          element="a"
          href="https://ahazou.app.goo.gl/site-ahz"
        />
        {/* <StoreBadge /> */}
      </div>
    </div>
  );
};

export default Reviews;
