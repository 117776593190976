import React from 'react'
import { Typography, Button, Icon } from '@ahazou/react-components'
import './style.css'

const Plans = ({ onDownloadClick }) => {
	return (
		<div className="plans" id="planos">
			<div className="plans__header"></div>
			<div className="plans__content">
				<div className="plans__title">
					<Typography className="plans__title--first" variant="displayXL">
						planos
					</Typography>
					<Typography className="plans__title--second" variant="bodyM">
						a assinatura da Ahazou é 10x mais em conta que um freelancer ou uma agência de publicidade.
					</Typography>
				</div>

				<div className="plans__cards">
					<div className="plans__card plans__cards--second">
						<div className="card__content">
							<div className="card__title">
								Ahazou PRIME
								<Icon
									size="l"
									icon="color_prime"
									className="plans__icon-prime"
								/>
								{/* <small>R$29,90/mês ou 199,90/ano</small> */}
							</div>
							<div className="card__list">
								<ul>
									<li>
										<Icon size="l" icon="mono_check_less" className="plans__icon-check" />
										<Typography className="plans__item-label" variant="bodyS" element="h4">
											<strong>
												acesso ilimitado&nbsp;
											</strong> a mais de 350 mil conteúdos prontos
										</Typography>
									</li>
									<li>
										<Icon size="l" icon="mono_check_less" className="plans__icon-check" />
										<Typography className="plans__item-label" variant="bodyS" element="h4">
											<strong>
												inclua o seu logo,&nbsp;
											</strong>
											e personalize os seus posts
										</Typography>
									</li>
									<li>
										<Icon size="l" icon="mono_check_less" className="plans__icon-check" />
										<Typography className="plans__item-label" variant="bodyS" element="h4">
											receba sugestões&nbsp;
											<strong>
												dos melhores posts&nbsp;
											</strong>
											 para o seu negócio
										</Typography>
									</li>
									<li>
										<Icon size="l" icon="mono_check_less" className="plans__icon-check" />
										<Typography className="plans__item-label" variant="bodyS" element="h4">
											<strong>
												compartilhe diretamente&nbsp;
											</strong>
											em suas redes sociais (sem marca d’água Ahazou)
										</Typography>
									</li>
									<li>
										<Icon size="l" icon="mono_check_less" className="plans__icon-check" />
										<Typography className="plans__item-label" variant="bodyS" element="h4">
											<strong>
												ganhe um calendário de postagem&nbsp;
											</strong>
											com o seu planejamento semanal e mensal
										</Typography>
									</li>
								</ul>
							</div>
							<Button
								element="a"
								label="experimente 7 dias grátis"
								className="plans__button"
								href="https://ahazou.app.goo.gl/site-cta-plano-prime"
								onClick={onDownloadClick}
							/>
						</div>
					</div>
					<div className="plans__card plans__cards--second">
						<div className="card__content">
							<div className="card__title">
								Ahazou PRO
								<Icon
									size="l"
									icon="color_prime"
									className="plans__icon-prime"
								/>
								{/* <small>R$49,90/mês ou R$299,90/ano</small> */}
							</div>
							<div className="card__list">
								<ul>
									<li>
										<Icon size="l" icon="mono_check_less" className="plans__icon-check" />
										<Typography className="plans__item-label" variant="bodyS" element="h4">
										 todos os recursos do <strong>PRIME</strong>
										</Typography>
									</li>
									<li>
										<Icon size="l" icon="mono_check_less" className="plans__icon-check" />
										<Typography className="plans__item-label" variant="bodyS" element="h4">
											tenha acesso à maior novidade do ahazou: <strong>conteúdos em vídeo</strong>
										</Typography>
									</li>
									<li>
										<Icon size="l" icon="mono_check_less" className="plans__icon-check" />
										<Typography className="plans__item-label" variant="bodyS" element="h4">
											selecione <strong>2 ou mais áreas</strong> de atuação com conteúdos exclusivos
										</Typography>
									</li>
									<li>
										<Icon size="l" icon="mono_check_less" className="plans__icon-check" />
										<Typography className="plans__item-label" variant="bodyS" element="h4">
											compartilhe o seu plano com até 10 pessoas
										</Typography>
									</li>
								</ul>
							</div>
							<Button
								element="a"
								label="experimente 7 dias grátis"
								className="plans__button"
								href="https://ahazou.app.goo.gl/site-cta-plano-pro"
								onClick={onDownloadClick}
							/>
						</div>
					</div>
				
				</div>
			</div>
		</div>
	)
}

export default Plans