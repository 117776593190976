import React from 'react'
import { Icon, Typography } from '@ahazou/react-components'
import GetScreen from '../GetScreen'
import './style.css'

const DarkList = () => {
    return (
        <div className="darklist">
            <div className="darklist__diagonal--first">
            </div>
            <div className="darklist__content">
                <div className="darklist__title">
                    <Typography variant="displayL">
                        olha o que você ganha com Ahazou
                    </Typography>
                </div>
                <div className="darklist__list">
                    <ul>
                        <GetScreen name="bodySM">
                            <li>
                                <Icon size="l" icon="mono_check_filled" className="darklist__icon-check" />
                                <span>
                                    <strong>conquiste mais clientes</strong> nas suas redes sociais
                                </span>
                            </li>
                            <li>
                                <Icon size="l" icon="mono_check_filled" className="darklist__icon-check" />
                                <span>
                                    <strong>aumente o engajamento</strong> do seu público
                                </span>
                            </li>
                            <li>
                                <Icon size="l" icon="mono_check_filled" className="darklist__icon-check" />
                                <span>
                                    <strong>economize</strong> 10h por semana
                                </span>
                            </li>
                            <li>
                                <Icon size="l" icon="mono_check_filled" className="darklist__icon-check" />
                                <span>
                                    <strong>passe credibilidade</strong> com posts criados por profissionais
                                </span>
                            </li>
                            <li>
                                <Icon size="l" icon="mono_check_filled" className="darklist__icon-check" />
                                <span>
                                    <strong>receba um calendário de publicações</strong> semanal sugerido por especialistas
                                </span>
                            </li>
                        </GetScreen>
                    </ul>
                </div>
            </div>
            <div className="darklist__diagonal--second">
            </div>
        </div>
    )
}

export default DarkList