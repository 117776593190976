import React from 'react'
import './style.css'
import GetScreen from '../GetScreen';

const HowTo = ({number, title, subtitle}) =>{
    return (
            <div className="how-to">
                <div className="how-to__icon-box">
                    <div className="how-to__icon-number">
                        {number}
                    </div>
                </div>
                <div className="how-to__text">
                    <div className= "how-to__text-title">
                        {title}
                    </div>
                    <GetScreen name="bodySM" className= "how-to__text-subtitle" >
                        {subtitle}
                    </GetScreen>
                </div>
            </div>
    );
}

HowTo.defaultProps = {
    number: null,
    title: null,
    subtitle: null
}


export default HowTo;