import React from 'react'
import GetScreen from '../GetScreen'
import './style.css'
import { Typography, Icon } from '@ahazou/react-components'


const Benefits = () => {
    return (
        <div className="benefits" >
            <div className="benefit benefit--first">
                <div className="benefit__icon-box">
                    <Icon
                        size="xl"
                        icon="mono_announce"
                        className="benefit__icon benefit__icon-annouce"
                    />
                </div>
                <div className="benefit__text">
                    <div className="benefit__text-title">
                        <Typography variant="displayM" className="">
                        compartilhe posts, stories e vídeos incríveis todos os dias
                        </Typography>
                    </div>
                    <div className="benefit__text-subtitle" >
                        <GetScreen name="bodySM" variant="bodyS" className="">
                            tenha uma <strong>presença forte</strong> nas redes sociais
                        </GetScreen>
                    </div>
                </div>
            </div>
            <div className="benefit benefit--second">
                <div className="benefit__icon-box">
                    <Icon
                        size="xl"
                        icon="mono_history"
                        className="benefit__icon benefit__icon-history"
                    />
                </div>
                <div className="benefit__text">
                    <div className="benefit__text-title">
                        <Typography variant="displayM" className="">
                            otimize seu tempo
                            </Typography>
                    </div>
                    <div className="benefit__text-subtitle" >
                        <GetScreen name="bodySM" variant="bodyS" className="">
                            bastam <strong>3 minutos</strong> por dia para alavancar suas redes sociais
                            </GetScreen>
                    </div>
                </div>
            </div>
            <div className="benefit benefit--third">
                <div className="benefit__icon-box">
                    <Icon
                        size="xl"
                        icon="mono_prime"
                        className="benefit__icon benefit__icon-prime"
                    />
                </div>
                <div className="benefit__text">
                    <div className="benefit__text-title">
                        <Typography variant="displayM" className="">
                            pague menos e receba mais
                            </Typography>
                    </div>
                    <div className="benefit__text-subtitle" >
                        <GetScreen name="bodySM" variant="bodyS" className="">
                        agências e freelancers custam 10x mais e não entregam conteúdos ilimitados todos os dias
                        </GetScreen>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Benefits;