import React from "react";
import { Typography, Button } from "@ahazou/react-components";
// import SocialIcons from "../SocialIcons";
import mb_img_right from "../../images/webp/main-banner_photos-right_3x-f2a1c1883c4cd8320c8b4e58673b5f64_resized.webp";
import mb_img_left from "../../images/webp/main-banner_photos-left_32_11zon.webp";
import bg_pinkblue from "../../images/webp/main-banner_pinkblue_24_11zon.webp";
import bg_blue_dots from "../../images/webp/how-to_blue-dots_71_11zon.webp";
import bg_yellow from "../../images/webp/main-banner_yellow1_22_11zon.webp";
import bg_pink from "../../images/webp/main-banner_pink_27_11zon.webp";
// import bg_avatar from "../../images/webp/main-banner_avatar_42_11zon.webp";
import bg_likes from "../../images/webp/main-banner_likes_36_11zon.webp";
import bg_hearts from "../../images/webp/main-banner_hearts_39_11zon.webp";
import GetScreen from "../GetScreen";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

const MainBanner = ({ title, subtitle, onDownloadClick }) => {
  return (
    <div className="main-banner" id="mainBanner">
      <div className="main-banner__header">
        <figure className="main-banner__posts--second">
          <img
            src={mb_img_left}
            alt="Left adorn"
            className="main-banner__posts__image main-banner__posts__image--second"
          />
        </figure>
        <div className="main-banner__descriptions" id="mainBanner">
          <h1 className="main-banner__description--first">{title}</h1>

          <Typography
            element="h2"
            variant="displayM"
            className="main-banner__description--second"
          >
            {subtitle}
          </Typography>

          {/* <div className="main-banner__button-div legend-btn">
            <a
              href="https://ahazou.app.goo.gl/site-cta1"
              className="main-banner__link"
              onClick={onDownloadClick}
            >
              <Button
                className="main-banner__button"
                label="experimente por 7 dias grátis"
                element="span"
              />
            </a>
            <GetScreen name="bodySM" variant="bodyS" className="text-btn">
              baixe o app ahazou para ter acesso a mais de 350 mil conteúdos
              prontos para você!
            </GetScreen>
          </div> */}
        </div>

        <figure className="main-banner__posts--first">
          <img
            src={mb_img_right}
            alt="Right adorn"
            className="main-banner__posts__image main-banner__posts__image--first"
          />
        </figure>
      </div>

      <figure className="main-banner__decoration">
        <img
          src={bg_pinkblue}
          alt="Background Blue"
          className="main-banner__bg-pinkblue"
        />
      </figure>
      <figure className="main-banner__decoration">
        <img
          src={bg_yellow}
          alt="Background yellow"
          className="main-banner__bg-yellow"
        />
      </figure>

      <figure className="main-banner__decoration">
        <img
          src={bg_pink}
          alt="Background Pink"
          className="main-banner__bg-pink"
        />
      </figure>

      <figure className="main-banner__decoration">
        <img
          src={bg_blue_dots}
          alt="Blue Dots"
          className="main-banner__bg-blue-dots"
        />
      </figure>

      {/* <figure className="main-banner__decoration">
        <img
          src={bg_avatar}
          alt="Background Avatar"
          className="main-banner__bg-avatar main-banner__bg-optional"
        />
      </figure> */}
      <figure className="main-banner__decoration">
        <img
          src={bg_likes}
          alt="Main Banner"
          className="main-banner__bg-likes main-banner__bg-optional"
        />
      </figure>
      <figure className="main-banner__decoration">
        <img
          src={bg_hearts}
          alt="Background Hearts"
          className="main-banner__bg-hearts main-banner__bg-optional"
        />
      </figure>
      <div className="main-page__test-button">
          <Button
            className="main-banner__button"
            label="experimente grátis"
            element="a"
            href="https://ahazou.app.goo.gl/site-ahz"
            onClick={onDownloadClick}
          />
        </div>
        <div className="main-banner__subtext">
          <div className="main-banner__textbtn">
            <GetScreen name="bodySM" variant="bodyS" className="text-btn">
              baixe o app ahazou para ter acesso a mais de 350 mil conteúdos
              prontos para você!
            </GetScreen>
          </div>
        </div>
      <div className="main-banner__footer">
        <div className="main-banner__text">
          <GetScreen name="title" className="main-banner__text--first">
            o que é o Ahazou?
          </GetScreen>
          <GetScreen name="bodySM" className="main-banner__text--second">
            o Ahazou é um aplicativo de conteúdos prontos para as redes sociais,
            como o Instagram, Facebook, WhatsApp e Tiktok
          </GetScreen>
          <GetScreen name="bodySM" className="main-banner__text--second">
            Já criamos mais de 350 mil conteúdos como posts, stories, vídeos e
            legendas, todos feitos por especialistas da área.
          </GetScreen>
        </div>

        {/* <div className="main-banner__social-icons">
          <SocialIcons />
        </div> */}
      </div>
    </div>
  );
};

MainBanner.defaultProps = {
  className: null,
  title: null,
  subtitle: null
};

export default MainBanner;
